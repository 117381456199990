$page-width-extra-narrow: 500px;
$page-width-narrow: 750px;
$page-width: 1000px;

$border-radius: 0.25rem;

$grid-columns:        12;
$grid-default-size: 'x2';
$grid-sizes: (
  'x0':    0,
  'x1':    7,
  'x2':   14,
  'x3':   21,
  'x4':   28,
  'x6':   42,
  'x8':   56,
  'x10':  70,
  'x12':  84,
  'x14':  98,
  'x16': 112,
);


$breakpoint-sizes: (
  'small':          0px,
  'medium':       640px,
  'large':       1024px,
  'extra-large': 1440px,
);
$breakpoints: (
  'small':       ( min-width: map-get($breakpoint-sizes,       'small')       ),
  'medium':      ( min-width: map-get($breakpoint-sizes,      'medium')       ),
  'large':       ( min-width: map-get($breakpoint-sizes,       'large')       ),
  'extra-large': ( min-width: map-get($breakpoint-sizes, 'extra-large')       ),

  'small-down':  ( max-width: map-get($breakpoint-sizes,      'medium') - 1px ),
  'medium-down': ( max-width: map-get($breakpoint-sizes,       'large') - 1px ),
  'large-down':  ( max-width: map-get($breakpoint-sizes, 'extra-large') - 1px ),
);


$black:               #000000;
$white:               #ffffff;

$primary-color:       #ed6540;
$secondary-color:     #286b74;
$primary-alt-color:   #343434;
$secondary-alt-color: #521b44;

$background-color:    #f7f7f7;
$border-color:        #dfdfdf;

// schema:
// "name" primaryColor readableTextColor
$colors: 
  "red" #DB2828 #FFFFFF,
  "orange" orange black,
  "yellow" yellow black,
  "olive" olive black,
  "green" green black,
  "teal" teal black,
  "blue" blue white,
  "violet" violet black,
  "purple" purple white,
  "pink" pink black,
  "brown" brown white,
  "grey" grey black,
  "black" black white;

$primary-font-family:      'Barlow';
$secondary-font-family: 'Open Sans';
