.grid {
  display: flex;
  flex-wrap: wrap;
  margin: -#{map-get($grid-sizes, $grid-default-size) / 2}px;
  >.column {
    &:empty {
      padding: 0;
    }
  }
}
.column {
  flex: 0 0 100%;
  max-width: 100%;
  padding: #{map-get($grid-sizes, $grid-default-size) / 2}px;
  &:empty {
    padding: 0;
  }
}

@each $breakpoint in map-keys($breakpoints) {
  $breakpoint-class: if($breakpoint == small, '', '-#{$breakpoint}');
  $breakpoint-param: if($breakpoint == small, false, $breakpoint);

  @include breakpoint($breakpoint-param) {

    @each $grid-size in map-keys($grid-sizes) {
      .grid-#{$grid-size}#{$breakpoint-class} {
        margin: -#{(map-get($grid-sizes, $grid-size) / 2)}px;
        >.column{
          padding: #{(map-get($grid-sizes, $grid-size) / 2)}px;
        }
      }
      .grid-x-#{$grid-size}#{$breakpoint-class} {
        margin-left: -#{(map-get($grid-sizes, $grid-size) / 2)}px;
        margin-right: -#{(map-get($grid-sizes, $grid-size) / 2)}px;
        >.column{
          padding-left: #{(map-get($grid-sizes, $grid-size) / 2)}px;
          padding-right: #{(map-get($grid-sizes, $grid-size) / 2)}px;
        }
      }
      .grid-y-#{$grid-size}#{$breakpoint-class} {
        margin-top: -#{(map-get($grid-sizes, $grid-size) / 2)}px;
        margin-bottom: -#{(map-get($grid-sizes, $grid-size) / 2)}px;
        >.column{
          padding-top: #{(map-get($grid-sizes, $grid-size) / 2)}px;
          padding-bottom: #{(map-get($grid-sizes, $grid-size) / 2)}px;
        }
      }
    }

    @for $grid-column from 1 through $grid-columns {
      .column-#{$grid-column}#{$breakpoint-class} {
        flex: 0 0 (100% / $grid-columns) * $grid-column;
        max-width: (100% / $grid-columns) * $grid-column;
      }
    }

  }
}
