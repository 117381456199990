@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
.grid {
  display: flex;
  flex-wrap: wrap;
  margin: -7px;
}
.grid > .column:empty {
  padding: 0;
}

.column {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 7px;
}
.column:empty {
  padding: 0;
}

.grid-x0 {
  margin: -0px;
}
.grid-x0 > .column {
  padding: 0px;
}

.grid-x-x0 {
  margin-left: -0px;
  margin-right: -0px;
}
.grid-x-x0 > .column {
  padding-left: 0px;
  padding-right: 0px;
}

.grid-y-x0 {
  margin-top: -0px;
  margin-bottom: -0px;
}
.grid-y-x0 > .column {
  padding-top: 0px;
  padding-bottom: 0px;
}

.grid-x1 {
  margin: -3.5px;
}
.grid-x1 > .column {
  padding: 3.5px;
}

.grid-x-x1 {
  margin-left: -3.5px;
  margin-right: -3.5px;
}
.grid-x-x1 > .column {
  padding-left: 3.5px;
  padding-right: 3.5px;
}

.grid-y-x1 {
  margin-top: -3.5px;
  margin-bottom: -3.5px;
}
.grid-y-x1 > .column {
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}

.grid-x2 {
  margin: -7px;
}
.grid-x2 > .column {
  padding: 7px;
}

.grid-x-x2 {
  margin-left: -7px;
  margin-right: -7px;
}
.grid-x-x2 > .column {
  padding-left: 7px;
  padding-right: 7px;
}

.grid-y-x2 {
  margin-top: -7px;
  margin-bottom: -7px;
}
.grid-y-x2 > .column {
  padding-top: 7px;
  padding-bottom: 7px;
}

.grid-x3 {
  margin: -10.5px;
}
.grid-x3 > .column {
  padding: 10.5px;
}

.grid-x-x3 {
  margin-left: -10.5px;
  margin-right: -10.5px;
}
.grid-x-x3 > .column {
  padding-left: 10.5px;
  padding-right: 10.5px;
}

.grid-y-x3 {
  margin-top: -10.5px;
  margin-bottom: -10.5px;
}
.grid-y-x3 > .column {
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}

.grid-x4 {
  margin: -14px;
}
.grid-x4 > .column {
  padding: 14px;
}

.grid-x-x4 {
  margin-left: -14px;
  margin-right: -14px;
}
.grid-x-x4 > .column {
  padding-left: 14px;
  padding-right: 14px;
}

.grid-y-x4 {
  margin-top: -14px;
  margin-bottom: -14px;
}
.grid-y-x4 > .column {
  padding-top: 14px;
  padding-bottom: 14px;
}

.grid-x6 {
  margin: -21px;
}
.grid-x6 > .column {
  padding: 21px;
}

.grid-x-x6 {
  margin-left: -21px;
  margin-right: -21px;
}
.grid-x-x6 > .column {
  padding-left: 21px;
  padding-right: 21px;
}

.grid-y-x6 {
  margin-top: -21px;
  margin-bottom: -21px;
}
.grid-y-x6 > .column {
  padding-top: 21px;
  padding-bottom: 21px;
}

.grid-x8 {
  margin: -28px;
}
.grid-x8 > .column {
  padding: 28px;
}

.grid-x-x8 {
  margin-left: -28px;
  margin-right: -28px;
}
.grid-x-x8 > .column {
  padding-left: 28px;
  padding-right: 28px;
}

.grid-y-x8 {
  margin-top: -28px;
  margin-bottom: -28px;
}
.grid-y-x8 > .column {
  padding-top: 28px;
  padding-bottom: 28px;
}

.grid-x10 {
  margin: -35px;
}
.grid-x10 > .column {
  padding: 35px;
}

.grid-x-x10 {
  margin-left: -35px;
  margin-right: -35px;
}
.grid-x-x10 > .column {
  padding-left: 35px;
  padding-right: 35px;
}

.grid-y-x10 {
  margin-top: -35px;
  margin-bottom: -35px;
}
.grid-y-x10 > .column {
  padding-top: 35px;
  padding-bottom: 35px;
}

.grid-x12 {
  margin: -42px;
}
.grid-x12 > .column {
  padding: 42px;
}

.grid-x-x12 {
  margin-left: -42px;
  margin-right: -42px;
}
.grid-x-x12 > .column {
  padding-left: 42px;
  padding-right: 42px;
}

.grid-y-x12 {
  margin-top: -42px;
  margin-bottom: -42px;
}
.grid-y-x12 > .column {
  padding-top: 42px;
  padding-bottom: 42px;
}

.grid-x14 {
  margin: -49px;
}
.grid-x14 > .column {
  padding: 49px;
}

.grid-x-x14 {
  margin-left: -49px;
  margin-right: -49px;
}
.grid-x-x14 > .column {
  padding-left: 49px;
  padding-right: 49px;
}

.grid-y-x14 {
  margin-top: -49px;
  margin-bottom: -49px;
}
.grid-y-x14 > .column {
  padding-top: 49px;
  padding-bottom: 49px;
}

.grid-x16 {
  margin: -56px;
}
.grid-x16 > .column {
  padding: 56px;
}

.grid-x-x16 {
  margin-left: -56px;
  margin-right: -56px;
}
.grid-x-x16 > .column {
  padding-left: 56px;
  padding-right: 56px;
}

.grid-y-x16 {
  margin-top: -56px;
  margin-bottom: -56px;
}
.grid-y-x16 > .column {
  padding-top: 56px;
  padding-bottom: 56px;
}

.column-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.column-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.column-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.column-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.column-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.column-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.column-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.column-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.column-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.column-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.column-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.column-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 640px) {
  .grid-x0-medium {
    margin: -0px;
  }
  .grid-x0-medium > .column {
    padding: 0px;
  }
  .grid-x-x0-medium {
    margin-left: -0px;
    margin-right: -0px;
  }
  .grid-x-x0-medium > .column {
    padding-left: 0px;
    padding-right: 0px;
  }
  .grid-y-x0-medium {
    margin-top: -0px;
    margin-bottom: -0px;
  }
  .grid-y-x0-medium > .column {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .grid-x1-medium {
    margin: -3.5px;
  }
  .grid-x1-medium > .column {
    padding: 3.5px;
  }
  .grid-x-x1-medium {
    margin-left: -3.5px;
    margin-right: -3.5px;
  }
  .grid-x-x1-medium > .column {
    padding-left: 3.5px;
    padding-right: 3.5px;
  }
  .grid-y-x1-medium {
    margin-top: -3.5px;
    margin-bottom: -3.5px;
  }
  .grid-y-x1-medium > .column {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }
  .grid-x2-medium {
    margin: -7px;
  }
  .grid-x2-medium > .column {
    padding: 7px;
  }
  .grid-x-x2-medium {
    margin-left: -7px;
    margin-right: -7px;
  }
  .grid-x-x2-medium > .column {
    padding-left: 7px;
    padding-right: 7px;
  }
  .grid-y-x2-medium {
    margin-top: -7px;
    margin-bottom: -7px;
  }
  .grid-y-x2-medium > .column {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .grid-x3-medium {
    margin: -10.5px;
  }
  .grid-x3-medium > .column {
    padding: 10.5px;
  }
  .grid-x-x3-medium {
    margin-left: -10.5px;
    margin-right: -10.5px;
  }
  .grid-x-x3-medium > .column {
    padding-left: 10.5px;
    padding-right: 10.5px;
  }
  .grid-y-x3-medium {
    margin-top: -10.5px;
    margin-bottom: -10.5px;
  }
  .grid-y-x3-medium > .column {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
  .grid-x4-medium {
    margin: -14px;
  }
  .grid-x4-medium > .column {
    padding: 14px;
  }
  .grid-x-x4-medium {
    margin-left: -14px;
    margin-right: -14px;
  }
  .grid-x-x4-medium > .column {
    padding-left: 14px;
    padding-right: 14px;
  }
  .grid-y-x4-medium {
    margin-top: -14px;
    margin-bottom: -14px;
  }
  .grid-y-x4-medium > .column {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .grid-x6-medium {
    margin: -21px;
  }
  .grid-x6-medium > .column {
    padding: 21px;
  }
  .grid-x-x6-medium {
    margin-left: -21px;
    margin-right: -21px;
  }
  .grid-x-x6-medium > .column {
    padding-left: 21px;
    padding-right: 21px;
  }
  .grid-y-x6-medium {
    margin-top: -21px;
    margin-bottom: -21px;
  }
  .grid-y-x6-medium > .column {
    padding-top: 21px;
    padding-bottom: 21px;
  }
  .grid-x8-medium {
    margin: -28px;
  }
  .grid-x8-medium > .column {
    padding: 28px;
  }
  .grid-x-x8-medium {
    margin-left: -28px;
    margin-right: -28px;
  }
  .grid-x-x8-medium > .column {
    padding-left: 28px;
    padding-right: 28px;
  }
  .grid-y-x8-medium {
    margin-top: -28px;
    margin-bottom: -28px;
  }
  .grid-y-x8-medium > .column {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .grid-x10-medium {
    margin: -35px;
  }
  .grid-x10-medium > .column {
    padding: 35px;
  }
  .grid-x-x10-medium {
    margin-left: -35px;
    margin-right: -35px;
  }
  .grid-x-x10-medium > .column {
    padding-left: 35px;
    padding-right: 35px;
  }
  .grid-y-x10-medium {
    margin-top: -35px;
    margin-bottom: -35px;
  }
  .grid-y-x10-medium > .column {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .grid-x12-medium {
    margin: -42px;
  }
  .grid-x12-medium > .column {
    padding: 42px;
  }
  .grid-x-x12-medium {
    margin-left: -42px;
    margin-right: -42px;
  }
  .grid-x-x12-medium > .column {
    padding-left: 42px;
    padding-right: 42px;
  }
  .grid-y-x12-medium {
    margin-top: -42px;
    margin-bottom: -42px;
  }
  .grid-y-x12-medium > .column {
    padding-top: 42px;
    padding-bottom: 42px;
  }
  .grid-x14-medium {
    margin: -49px;
  }
  .grid-x14-medium > .column {
    padding: 49px;
  }
  .grid-x-x14-medium {
    margin-left: -49px;
    margin-right: -49px;
  }
  .grid-x-x14-medium > .column {
    padding-left: 49px;
    padding-right: 49px;
  }
  .grid-y-x14-medium {
    margin-top: -49px;
    margin-bottom: -49px;
  }
  .grid-y-x14-medium > .column {
    padding-top: 49px;
    padding-bottom: 49px;
  }
  .grid-x16-medium {
    margin: -56px;
  }
  .grid-x16-medium > .column {
    padding: 56px;
  }
  .grid-x-x16-medium {
    margin-left: -56px;
    margin-right: -56px;
  }
  .grid-x-x16-medium > .column {
    padding-left: 56px;
    padding-right: 56px;
  }
  .grid-y-x16-medium {
    margin-top: -56px;
    margin-bottom: -56px;
  }
  .grid-y-x16-medium > .column {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .column-1-medium {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .column-2-medium {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .column-3-medium {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .column-4-medium {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .column-5-medium {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .column-6-medium {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .column-7-medium {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .column-8-medium {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .column-9-medium {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .column-10-medium {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .column-11-medium {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .column-12-medium {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  .grid-x0-large {
    margin: -0px;
  }
  .grid-x0-large > .column {
    padding: 0px;
  }
  .grid-x-x0-large {
    margin-left: -0px;
    margin-right: -0px;
  }
  .grid-x-x0-large > .column {
    padding-left: 0px;
    padding-right: 0px;
  }
  .grid-y-x0-large {
    margin-top: -0px;
    margin-bottom: -0px;
  }
  .grid-y-x0-large > .column {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .grid-x1-large {
    margin: -3.5px;
  }
  .grid-x1-large > .column {
    padding: 3.5px;
  }
  .grid-x-x1-large {
    margin-left: -3.5px;
    margin-right: -3.5px;
  }
  .grid-x-x1-large > .column {
    padding-left: 3.5px;
    padding-right: 3.5px;
  }
  .grid-y-x1-large {
    margin-top: -3.5px;
    margin-bottom: -3.5px;
  }
  .grid-y-x1-large > .column {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }
  .grid-x2-large {
    margin: -7px;
  }
  .grid-x2-large > .column {
    padding: 7px;
  }
  .grid-x-x2-large {
    margin-left: -7px;
    margin-right: -7px;
  }
  .grid-x-x2-large > .column {
    padding-left: 7px;
    padding-right: 7px;
  }
  .grid-y-x2-large {
    margin-top: -7px;
    margin-bottom: -7px;
  }
  .grid-y-x2-large > .column {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .grid-x3-large {
    margin: -10.5px;
  }
  .grid-x3-large > .column {
    padding: 10.5px;
  }
  .grid-x-x3-large {
    margin-left: -10.5px;
    margin-right: -10.5px;
  }
  .grid-x-x3-large > .column {
    padding-left: 10.5px;
    padding-right: 10.5px;
  }
  .grid-y-x3-large {
    margin-top: -10.5px;
    margin-bottom: -10.5px;
  }
  .grid-y-x3-large > .column {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
  .grid-x4-large {
    margin: -14px;
  }
  .grid-x4-large > .column {
    padding: 14px;
  }
  .grid-x-x4-large {
    margin-left: -14px;
    margin-right: -14px;
  }
  .grid-x-x4-large > .column {
    padding-left: 14px;
    padding-right: 14px;
  }
  .grid-y-x4-large {
    margin-top: -14px;
    margin-bottom: -14px;
  }
  .grid-y-x4-large > .column {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .grid-x6-large {
    margin: -21px;
  }
  .grid-x6-large > .column {
    padding: 21px;
  }
  .grid-x-x6-large {
    margin-left: -21px;
    margin-right: -21px;
  }
  .grid-x-x6-large > .column {
    padding-left: 21px;
    padding-right: 21px;
  }
  .grid-y-x6-large {
    margin-top: -21px;
    margin-bottom: -21px;
  }
  .grid-y-x6-large > .column {
    padding-top: 21px;
    padding-bottom: 21px;
  }
  .grid-x8-large {
    margin: -28px;
  }
  .grid-x8-large > .column {
    padding: 28px;
  }
  .grid-x-x8-large {
    margin-left: -28px;
    margin-right: -28px;
  }
  .grid-x-x8-large > .column {
    padding-left: 28px;
    padding-right: 28px;
  }
  .grid-y-x8-large {
    margin-top: -28px;
    margin-bottom: -28px;
  }
  .grid-y-x8-large > .column {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .grid-x10-large {
    margin: -35px;
  }
  .grid-x10-large > .column {
    padding: 35px;
  }
  .grid-x-x10-large {
    margin-left: -35px;
    margin-right: -35px;
  }
  .grid-x-x10-large > .column {
    padding-left: 35px;
    padding-right: 35px;
  }
  .grid-y-x10-large {
    margin-top: -35px;
    margin-bottom: -35px;
  }
  .grid-y-x10-large > .column {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .grid-x12-large {
    margin: -42px;
  }
  .grid-x12-large > .column {
    padding: 42px;
  }
  .grid-x-x12-large {
    margin-left: -42px;
    margin-right: -42px;
  }
  .grid-x-x12-large > .column {
    padding-left: 42px;
    padding-right: 42px;
  }
  .grid-y-x12-large {
    margin-top: -42px;
    margin-bottom: -42px;
  }
  .grid-y-x12-large > .column {
    padding-top: 42px;
    padding-bottom: 42px;
  }
  .grid-x14-large {
    margin: -49px;
  }
  .grid-x14-large > .column {
    padding: 49px;
  }
  .grid-x-x14-large {
    margin-left: -49px;
    margin-right: -49px;
  }
  .grid-x-x14-large > .column {
    padding-left: 49px;
    padding-right: 49px;
  }
  .grid-y-x14-large {
    margin-top: -49px;
    margin-bottom: -49px;
  }
  .grid-y-x14-large > .column {
    padding-top: 49px;
    padding-bottom: 49px;
  }
  .grid-x16-large {
    margin: -56px;
  }
  .grid-x16-large > .column {
    padding: 56px;
  }
  .grid-x-x16-large {
    margin-left: -56px;
    margin-right: -56px;
  }
  .grid-x-x16-large > .column {
    padding-left: 56px;
    padding-right: 56px;
  }
  .grid-y-x16-large {
    margin-top: -56px;
    margin-bottom: -56px;
  }
  .grid-y-x16-large > .column {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .column-1-large {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .column-2-large {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .column-3-large {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .column-4-large {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .column-5-large {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .column-6-large {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .column-7-large {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .column-8-large {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .column-9-large {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .column-10-large {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .column-11-large {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .column-12-large {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1440px) {
  .grid-x0-extra-large {
    margin: -0px;
  }
  .grid-x0-extra-large > .column {
    padding: 0px;
  }
  .grid-x-x0-extra-large {
    margin-left: -0px;
    margin-right: -0px;
  }
  .grid-x-x0-extra-large > .column {
    padding-left: 0px;
    padding-right: 0px;
  }
  .grid-y-x0-extra-large {
    margin-top: -0px;
    margin-bottom: -0px;
  }
  .grid-y-x0-extra-large > .column {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .grid-x1-extra-large {
    margin: -3.5px;
  }
  .grid-x1-extra-large > .column {
    padding: 3.5px;
  }
  .grid-x-x1-extra-large {
    margin-left: -3.5px;
    margin-right: -3.5px;
  }
  .grid-x-x1-extra-large > .column {
    padding-left: 3.5px;
    padding-right: 3.5px;
  }
  .grid-y-x1-extra-large {
    margin-top: -3.5px;
    margin-bottom: -3.5px;
  }
  .grid-y-x1-extra-large > .column {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }
  .grid-x2-extra-large {
    margin: -7px;
  }
  .grid-x2-extra-large > .column {
    padding: 7px;
  }
  .grid-x-x2-extra-large {
    margin-left: -7px;
    margin-right: -7px;
  }
  .grid-x-x2-extra-large > .column {
    padding-left: 7px;
    padding-right: 7px;
  }
  .grid-y-x2-extra-large {
    margin-top: -7px;
    margin-bottom: -7px;
  }
  .grid-y-x2-extra-large > .column {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .grid-x3-extra-large {
    margin: -10.5px;
  }
  .grid-x3-extra-large > .column {
    padding: 10.5px;
  }
  .grid-x-x3-extra-large {
    margin-left: -10.5px;
    margin-right: -10.5px;
  }
  .grid-x-x3-extra-large > .column {
    padding-left: 10.5px;
    padding-right: 10.5px;
  }
  .grid-y-x3-extra-large {
    margin-top: -10.5px;
    margin-bottom: -10.5px;
  }
  .grid-y-x3-extra-large > .column {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
  .grid-x4-extra-large {
    margin: -14px;
  }
  .grid-x4-extra-large > .column {
    padding: 14px;
  }
  .grid-x-x4-extra-large {
    margin-left: -14px;
    margin-right: -14px;
  }
  .grid-x-x4-extra-large > .column {
    padding-left: 14px;
    padding-right: 14px;
  }
  .grid-y-x4-extra-large {
    margin-top: -14px;
    margin-bottom: -14px;
  }
  .grid-y-x4-extra-large > .column {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .grid-x6-extra-large {
    margin: -21px;
  }
  .grid-x6-extra-large > .column {
    padding: 21px;
  }
  .grid-x-x6-extra-large {
    margin-left: -21px;
    margin-right: -21px;
  }
  .grid-x-x6-extra-large > .column {
    padding-left: 21px;
    padding-right: 21px;
  }
  .grid-y-x6-extra-large {
    margin-top: -21px;
    margin-bottom: -21px;
  }
  .grid-y-x6-extra-large > .column {
    padding-top: 21px;
    padding-bottom: 21px;
  }
  .grid-x8-extra-large {
    margin: -28px;
  }
  .grid-x8-extra-large > .column {
    padding: 28px;
  }
  .grid-x-x8-extra-large {
    margin-left: -28px;
    margin-right: -28px;
  }
  .grid-x-x8-extra-large > .column {
    padding-left: 28px;
    padding-right: 28px;
  }
  .grid-y-x8-extra-large {
    margin-top: -28px;
    margin-bottom: -28px;
  }
  .grid-y-x8-extra-large > .column {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .grid-x10-extra-large {
    margin: -35px;
  }
  .grid-x10-extra-large > .column {
    padding: 35px;
  }
  .grid-x-x10-extra-large {
    margin-left: -35px;
    margin-right: -35px;
  }
  .grid-x-x10-extra-large > .column {
    padding-left: 35px;
    padding-right: 35px;
  }
  .grid-y-x10-extra-large {
    margin-top: -35px;
    margin-bottom: -35px;
  }
  .grid-y-x10-extra-large > .column {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .grid-x12-extra-large {
    margin: -42px;
  }
  .grid-x12-extra-large > .column {
    padding: 42px;
  }
  .grid-x-x12-extra-large {
    margin-left: -42px;
    margin-right: -42px;
  }
  .grid-x-x12-extra-large > .column {
    padding-left: 42px;
    padding-right: 42px;
  }
  .grid-y-x12-extra-large {
    margin-top: -42px;
    margin-bottom: -42px;
  }
  .grid-y-x12-extra-large > .column {
    padding-top: 42px;
    padding-bottom: 42px;
  }
  .grid-x14-extra-large {
    margin: -49px;
  }
  .grid-x14-extra-large > .column {
    padding: 49px;
  }
  .grid-x-x14-extra-large {
    margin-left: -49px;
    margin-right: -49px;
  }
  .grid-x-x14-extra-large > .column {
    padding-left: 49px;
    padding-right: 49px;
  }
  .grid-y-x14-extra-large {
    margin-top: -49px;
    margin-bottom: -49px;
  }
  .grid-y-x14-extra-large > .column {
    padding-top: 49px;
    padding-bottom: 49px;
  }
  .grid-x16-extra-large {
    margin: -56px;
  }
  .grid-x16-extra-large > .column {
    padding: 56px;
  }
  .grid-x-x16-extra-large {
    margin-left: -56px;
    margin-right: -56px;
  }
  .grid-x-x16-extra-large > .column {
    padding-left: 56px;
    padding-right: 56px;
  }
  .grid-y-x16-extra-large {
    margin-top: -56px;
    margin-bottom: -56px;
  }
  .grid-y-x16-extra-large > .column {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .column-1-extra-large {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .column-2-extra-large {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .column-3-extra-large {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .column-4-extra-large {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .column-5-extra-large {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .column-6-extra-large {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .column-7-extra-large {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .column-8-extra-large {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .column-9-extra-large {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .column-10-extra-large {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .column-11-extra-large {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .column-12-extra-large {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 639px) {
  .grid-x0-small-down {
    margin: -0px;
  }
  .grid-x0-small-down > .column {
    padding: 0px;
  }
  .grid-x-x0-small-down {
    margin-left: -0px;
    margin-right: -0px;
  }
  .grid-x-x0-small-down > .column {
    padding-left: 0px;
    padding-right: 0px;
  }
  .grid-y-x0-small-down {
    margin-top: -0px;
    margin-bottom: -0px;
  }
  .grid-y-x0-small-down > .column {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .grid-x1-small-down {
    margin: -3.5px;
  }
  .grid-x1-small-down > .column {
    padding: 3.5px;
  }
  .grid-x-x1-small-down {
    margin-left: -3.5px;
    margin-right: -3.5px;
  }
  .grid-x-x1-small-down > .column {
    padding-left: 3.5px;
    padding-right: 3.5px;
  }
  .grid-y-x1-small-down {
    margin-top: -3.5px;
    margin-bottom: -3.5px;
  }
  .grid-y-x1-small-down > .column {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }
  .grid-x2-small-down {
    margin: -7px;
  }
  .grid-x2-small-down > .column {
    padding: 7px;
  }
  .grid-x-x2-small-down {
    margin-left: -7px;
    margin-right: -7px;
  }
  .grid-x-x2-small-down > .column {
    padding-left: 7px;
    padding-right: 7px;
  }
  .grid-y-x2-small-down {
    margin-top: -7px;
    margin-bottom: -7px;
  }
  .grid-y-x2-small-down > .column {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .grid-x3-small-down {
    margin: -10.5px;
  }
  .grid-x3-small-down > .column {
    padding: 10.5px;
  }
  .grid-x-x3-small-down {
    margin-left: -10.5px;
    margin-right: -10.5px;
  }
  .grid-x-x3-small-down > .column {
    padding-left: 10.5px;
    padding-right: 10.5px;
  }
  .grid-y-x3-small-down {
    margin-top: -10.5px;
    margin-bottom: -10.5px;
  }
  .grid-y-x3-small-down > .column {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
  .grid-x4-small-down {
    margin: -14px;
  }
  .grid-x4-small-down > .column {
    padding: 14px;
  }
  .grid-x-x4-small-down {
    margin-left: -14px;
    margin-right: -14px;
  }
  .grid-x-x4-small-down > .column {
    padding-left: 14px;
    padding-right: 14px;
  }
  .grid-y-x4-small-down {
    margin-top: -14px;
    margin-bottom: -14px;
  }
  .grid-y-x4-small-down > .column {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .grid-x6-small-down {
    margin: -21px;
  }
  .grid-x6-small-down > .column {
    padding: 21px;
  }
  .grid-x-x6-small-down {
    margin-left: -21px;
    margin-right: -21px;
  }
  .grid-x-x6-small-down > .column {
    padding-left: 21px;
    padding-right: 21px;
  }
  .grid-y-x6-small-down {
    margin-top: -21px;
    margin-bottom: -21px;
  }
  .grid-y-x6-small-down > .column {
    padding-top: 21px;
    padding-bottom: 21px;
  }
  .grid-x8-small-down {
    margin: -28px;
  }
  .grid-x8-small-down > .column {
    padding: 28px;
  }
  .grid-x-x8-small-down {
    margin-left: -28px;
    margin-right: -28px;
  }
  .grid-x-x8-small-down > .column {
    padding-left: 28px;
    padding-right: 28px;
  }
  .grid-y-x8-small-down {
    margin-top: -28px;
    margin-bottom: -28px;
  }
  .grid-y-x8-small-down > .column {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .grid-x10-small-down {
    margin: -35px;
  }
  .grid-x10-small-down > .column {
    padding: 35px;
  }
  .grid-x-x10-small-down {
    margin-left: -35px;
    margin-right: -35px;
  }
  .grid-x-x10-small-down > .column {
    padding-left: 35px;
    padding-right: 35px;
  }
  .grid-y-x10-small-down {
    margin-top: -35px;
    margin-bottom: -35px;
  }
  .grid-y-x10-small-down > .column {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .grid-x12-small-down {
    margin: -42px;
  }
  .grid-x12-small-down > .column {
    padding: 42px;
  }
  .grid-x-x12-small-down {
    margin-left: -42px;
    margin-right: -42px;
  }
  .grid-x-x12-small-down > .column {
    padding-left: 42px;
    padding-right: 42px;
  }
  .grid-y-x12-small-down {
    margin-top: -42px;
    margin-bottom: -42px;
  }
  .grid-y-x12-small-down > .column {
    padding-top: 42px;
    padding-bottom: 42px;
  }
  .grid-x14-small-down {
    margin: -49px;
  }
  .grid-x14-small-down > .column {
    padding: 49px;
  }
  .grid-x-x14-small-down {
    margin-left: -49px;
    margin-right: -49px;
  }
  .grid-x-x14-small-down > .column {
    padding-left: 49px;
    padding-right: 49px;
  }
  .grid-y-x14-small-down {
    margin-top: -49px;
    margin-bottom: -49px;
  }
  .grid-y-x14-small-down > .column {
    padding-top: 49px;
    padding-bottom: 49px;
  }
  .grid-x16-small-down {
    margin: -56px;
  }
  .grid-x16-small-down > .column {
    padding: 56px;
  }
  .grid-x-x16-small-down {
    margin-left: -56px;
    margin-right: -56px;
  }
  .grid-x-x16-small-down > .column {
    padding-left: 56px;
    padding-right: 56px;
  }
  .grid-y-x16-small-down {
    margin-top: -56px;
    margin-bottom: -56px;
  }
  .grid-y-x16-small-down > .column {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .column-1-small-down {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .column-2-small-down {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .column-3-small-down {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .column-4-small-down {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .column-5-small-down {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .column-6-small-down {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .column-7-small-down {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .column-8-small-down {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .column-9-small-down {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .column-10-small-down {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .column-11-small-down {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .column-12-small-down {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 1023px) {
  .grid-x0-medium-down {
    margin: -0px;
  }
  .grid-x0-medium-down > .column {
    padding: 0px;
  }
  .grid-x-x0-medium-down {
    margin-left: -0px;
    margin-right: -0px;
  }
  .grid-x-x0-medium-down > .column {
    padding-left: 0px;
    padding-right: 0px;
  }
  .grid-y-x0-medium-down {
    margin-top: -0px;
    margin-bottom: -0px;
  }
  .grid-y-x0-medium-down > .column {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .grid-x1-medium-down {
    margin: -3.5px;
  }
  .grid-x1-medium-down > .column {
    padding: 3.5px;
  }
  .grid-x-x1-medium-down {
    margin-left: -3.5px;
    margin-right: -3.5px;
  }
  .grid-x-x1-medium-down > .column {
    padding-left: 3.5px;
    padding-right: 3.5px;
  }
  .grid-y-x1-medium-down {
    margin-top: -3.5px;
    margin-bottom: -3.5px;
  }
  .grid-y-x1-medium-down > .column {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }
  .grid-x2-medium-down {
    margin: -7px;
  }
  .grid-x2-medium-down > .column {
    padding: 7px;
  }
  .grid-x-x2-medium-down {
    margin-left: -7px;
    margin-right: -7px;
  }
  .grid-x-x2-medium-down > .column {
    padding-left: 7px;
    padding-right: 7px;
  }
  .grid-y-x2-medium-down {
    margin-top: -7px;
    margin-bottom: -7px;
  }
  .grid-y-x2-medium-down > .column {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .grid-x3-medium-down {
    margin: -10.5px;
  }
  .grid-x3-medium-down > .column {
    padding: 10.5px;
  }
  .grid-x-x3-medium-down {
    margin-left: -10.5px;
    margin-right: -10.5px;
  }
  .grid-x-x3-medium-down > .column {
    padding-left: 10.5px;
    padding-right: 10.5px;
  }
  .grid-y-x3-medium-down {
    margin-top: -10.5px;
    margin-bottom: -10.5px;
  }
  .grid-y-x3-medium-down > .column {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
  .grid-x4-medium-down {
    margin: -14px;
  }
  .grid-x4-medium-down > .column {
    padding: 14px;
  }
  .grid-x-x4-medium-down {
    margin-left: -14px;
    margin-right: -14px;
  }
  .grid-x-x4-medium-down > .column {
    padding-left: 14px;
    padding-right: 14px;
  }
  .grid-y-x4-medium-down {
    margin-top: -14px;
    margin-bottom: -14px;
  }
  .grid-y-x4-medium-down > .column {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .grid-x6-medium-down {
    margin: -21px;
  }
  .grid-x6-medium-down > .column {
    padding: 21px;
  }
  .grid-x-x6-medium-down {
    margin-left: -21px;
    margin-right: -21px;
  }
  .grid-x-x6-medium-down > .column {
    padding-left: 21px;
    padding-right: 21px;
  }
  .grid-y-x6-medium-down {
    margin-top: -21px;
    margin-bottom: -21px;
  }
  .grid-y-x6-medium-down > .column {
    padding-top: 21px;
    padding-bottom: 21px;
  }
  .grid-x8-medium-down {
    margin: -28px;
  }
  .grid-x8-medium-down > .column {
    padding: 28px;
  }
  .grid-x-x8-medium-down {
    margin-left: -28px;
    margin-right: -28px;
  }
  .grid-x-x8-medium-down > .column {
    padding-left: 28px;
    padding-right: 28px;
  }
  .grid-y-x8-medium-down {
    margin-top: -28px;
    margin-bottom: -28px;
  }
  .grid-y-x8-medium-down > .column {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .grid-x10-medium-down {
    margin: -35px;
  }
  .grid-x10-medium-down > .column {
    padding: 35px;
  }
  .grid-x-x10-medium-down {
    margin-left: -35px;
    margin-right: -35px;
  }
  .grid-x-x10-medium-down > .column {
    padding-left: 35px;
    padding-right: 35px;
  }
  .grid-y-x10-medium-down {
    margin-top: -35px;
    margin-bottom: -35px;
  }
  .grid-y-x10-medium-down > .column {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .grid-x12-medium-down {
    margin: -42px;
  }
  .grid-x12-medium-down > .column {
    padding: 42px;
  }
  .grid-x-x12-medium-down {
    margin-left: -42px;
    margin-right: -42px;
  }
  .grid-x-x12-medium-down > .column {
    padding-left: 42px;
    padding-right: 42px;
  }
  .grid-y-x12-medium-down {
    margin-top: -42px;
    margin-bottom: -42px;
  }
  .grid-y-x12-medium-down > .column {
    padding-top: 42px;
    padding-bottom: 42px;
  }
  .grid-x14-medium-down {
    margin: -49px;
  }
  .grid-x14-medium-down > .column {
    padding: 49px;
  }
  .grid-x-x14-medium-down {
    margin-left: -49px;
    margin-right: -49px;
  }
  .grid-x-x14-medium-down > .column {
    padding-left: 49px;
    padding-right: 49px;
  }
  .grid-y-x14-medium-down {
    margin-top: -49px;
    margin-bottom: -49px;
  }
  .grid-y-x14-medium-down > .column {
    padding-top: 49px;
    padding-bottom: 49px;
  }
  .grid-x16-medium-down {
    margin: -56px;
  }
  .grid-x16-medium-down > .column {
    padding: 56px;
  }
  .grid-x-x16-medium-down {
    margin-left: -56px;
    margin-right: -56px;
  }
  .grid-x-x16-medium-down > .column {
    padding-left: 56px;
    padding-right: 56px;
  }
  .grid-y-x16-medium-down {
    margin-top: -56px;
    margin-bottom: -56px;
  }
  .grid-y-x16-medium-down > .column {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .column-1-medium-down {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .column-2-medium-down {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .column-3-medium-down {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .column-4-medium-down {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .column-5-medium-down {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .column-6-medium-down {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .column-7-medium-down {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .column-8-medium-down {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .column-9-medium-down {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .column-10-medium-down {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .column-11-medium-down {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .column-12-medium-down {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 1439px) {
  .grid-x0-large-down {
    margin: -0px;
  }
  .grid-x0-large-down > .column {
    padding: 0px;
  }
  .grid-x-x0-large-down {
    margin-left: -0px;
    margin-right: -0px;
  }
  .grid-x-x0-large-down > .column {
    padding-left: 0px;
    padding-right: 0px;
  }
  .grid-y-x0-large-down {
    margin-top: -0px;
    margin-bottom: -0px;
  }
  .grid-y-x0-large-down > .column {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .grid-x1-large-down {
    margin: -3.5px;
  }
  .grid-x1-large-down > .column {
    padding: 3.5px;
  }
  .grid-x-x1-large-down {
    margin-left: -3.5px;
    margin-right: -3.5px;
  }
  .grid-x-x1-large-down > .column {
    padding-left: 3.5px;
    padding-right: 3.5px;
  }
  .grid-y-x1-large-down {
    margin-top: -3.5px;
    margin-bottom: -3.5px;
  }
  .grid-y-x1-large-down > .column {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }
  .grid-x2-large-down {
    margin: -7px;
  }
  .grid-x2-large-down > .column {
    padding: 7px;
  }
  .grid-x-x2-large-down {
    margin-left: -7px;
    margin-right: -7px;
  }
  .grid-x-x2-large-down > .column {
    padding-left: 7px;
    padding-right: 7px;
  }
  .grid-y-x2-large-down {
    margin-top: -7px;
    margin-bottom: -7px;
  }
  .grid-y-x2-large-down > .column {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .grid-x3-large-down {
    margin: -10.5px;
  }
  .grid-x3-large-down > .column {
    padding: 10.5px;
  }
  .grid-x-x3-large-down {
    margin-left: -10.5px;
    margin-right: -10.5px;
  }
  .grid-x-x3-large-down > .column {
    padding-left: 10.5px;
    padding-right: 10.5px;
  }
  .grid-y-x3-large-down {
    margin-top: -10.5px;
    margin-bottom: -10.5px;
  }
  .grid-y-x3-large-down > .column {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
  .grid-x4-large-down {
    margin: -14px;
  }
  .grid-x4-large-down > .column {
    padding: 14px;
  }
  .grid-x-x4-large-down {
    margin-left: -14px;
    margin-right: -14px;
  }
  .grid-x-x4-large-down > .column {
    padding-left: 14px;
    padding-right: 14px;
  }
  .grid-y-x4-large-down {
    margin-top: -14px;
    margin-bottom: -14px;
  }
  .grid-y-x4-large-down > .column {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .grid-x6-large-down {
    margin: -21px;
  }
  .grid-x6-large-down > .column {
    padding: 21px;
  }
  .grid-x-x6-large-down {
    margin-left: -21px;
    margin-right: -21px;
  }
  .grid-x-x6-large-down > .column {
    padding-left: 21px;
    padding-right: 21px;
  }
  .grid-y-x6-large-down {
    margin-top: -21px;
    margin-bottom: -21px;
  }
  .grid-y-x6-large-down > .column {
    padding-top: 21px;
    padding-bottom: 21px;
  }
  .grid-x8-large-down {
    margin: -28px;
  }
  .grid-x8-large-down > .column {
    padding: 28px;
  }
  .grid-x-x8-large-down {
    margin-left: -28px;
    margin-right: -28px;
  }
  .grid-x-x8-large-down > .column {
    padding-left: 28px;
    padding-right: 28px;
  }
  .grid-y-x8-large-down {
    margin-top: -28px;
    margin-bottom: -28px;
  }
  .grid-y-x8-large-down > .column {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .grid-x10-large-down {
    margin: -35px;
  }
  .grid-x10-large-down > .column {
    padding: 35px;
  }
  .grid-x-x10-large-down {
    margin-left: -35px;
    margin-right: -35px;
  }
  .grid-x-x10-large-down > .column {
    padding-left: 35px;
    padding-right: 35px;
  }
  .grid-y-x10-large-down {
    margin-top: -35px;
    margin-bottom: -35px;
  }
  .grid-y-x10-large-down > .column {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .grid-x12-large-down {
    margin: -42px;
  }
  .grid-x12-large-down > .column {
    padding: 42px;
  }
  .grid-x-x12-large-down {
    margin-left: -42px;
    margin-right: -42px;
  }
  .grid-x-x12-large-down > .column {
    padding-left: 42px;
    padding-right: 42px;
  }
  .grid-y-x12-large-down {
    margin-top: -42px;
    margin-bottom: -42px;
  }
  .grid-y-x12-large-down > .column {
    padding-top: 42px;
    padding-bottom: 42px;
  }
  .grid-x14-large-down {
    margin: -49px;
  }
  .grid-x14-large-down > .column {
    padding: 49px;
  }
  .grid-x-x14-large-down {
    margin-left: -49px;
    margin-right: -49px;
  }
  .grid-x-x14-large-down > .column {
    padding-left: 49px;
    padding-right: 49px;
  }
  .grid-y-x14-large-down {
    margin-top: -49px;
    margin-bottom: -49px;
  }
  .grid-y-x14-large-down > .column {
    padding-top: 49px;
    padding-bottom: 49px;
  }
  .grid-x16-large-down {
    margin: -56px;
  }
  .grid-x16-large-down > .column {
    padding: 56px;
  }
  .grid-x-x16-large-down {
    margin-left: -56px;
    margin-right: -56px;
  }
  .grid-x-x16-large-down > .column {
    padding-left: 56px;
    padding-right: 56px;
  }
  .grid-y-x16-large-down {
    margin-top: -56px;
    margin-bottom: -56px;
  }
  .grid-y-x16-large-down > .column {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .column-1-large-down {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .column-2-large-down {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .column-3-large-down {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .column-4-large-down {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .column-5-large-down {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .column-6-large-down {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .column-7-large-down {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .column-8-large-down {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .column-9-large-down {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .column-10-large-down {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .column-11-large-down {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .column-12-large-down {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.container {
  max-width: 1028px;
  margin: 0 auto;
  padding: 0 14px;
}

.container-narrow {
  max-width: 778px;
}

.container-extra-narrow {
  max-width: 528px;
}

.max-width {
  max-width: 1000px;
  margin: 0 auto;
}

.max-width-narrow {
  max-width: 750px;
}

.max-width-extra-narrow {
  max-width: 500px;
}

* {
  @apply antialiased;
}

html {
  overflow: visible !important;
}

body {
  background-color: #f7f7f7;
  color: #286b74;
  font-weight: 500;
  line-height: 1.375;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.375;
  letter-spacing: -0.00625em;
}
p a {
  font-family: "Barlow";
}

a {
  color: #ed6540;
  text-decoration: underline;
  font-family: "Open Sans";
}
a:hover {
  color: rgb(228.4688995215, 65.7894736842, 21.5311004785);
  text-decoration: underline;
}

label,
.label {
  color: #343434;
  font-weight: 700;
}

.form-label {
  display: inline-block;
  margin: 0 0 7px;
}

.ui.header {
  color: #286b74;
  font-weight: 800;
  line-height: 1.125;
  text-rendering: optimizeLegibility;
}
.ui.header .sub.header {
  color: inherit;
  font-size: 0.5em !important;
  line-height: inherit;
}
.ui.header.page-title {
  margin-bottom: 2rem;
}
.ui.header.page-title:last-child {
  margin-bottom: 0;
}
.ui.header.secondary {
  color: #343434;
  font-weight: 700;
}

p .ui.button.link {
  font-family: "Barlow";
  font-weight: 500;
  letter-spacing: -0.00625em;
}

.ui.button {
  margin: 0;
  border-radius: 0.25rem !important;
  box-shadow: 0 0 0 1px #dfdfdf inset !important;
  background-color: #f7f7f7 !important;
  color: #343434 !important;
  font-family: "Open Sans";
  font-weight: 400;
  transition: none;
}
.ui.button:hover, .ui.button:focus {
  box-shadow: 0 0 0 1px rgb(191.125, 191.125, 191.125) inset !important;
}
.ui.button:active {
  background-color: rgb(243.8125, 243.8125, 243.8125) !important;
}
.ui.button.red {
  background-color: #DB2828 !important;
  color: #FFFFFF !important;
}
.ui.button.red:active {
  background-color: rgb(216.3919322709, 36.2330677291, 36.2330677291) !important;
}
.ui.button.orange {
  background-color: orange !important;
  color: black !important;
}
.ui.button.orange:active {
  background-color: rgb(248.625, 160.875, 0) !important;
}
.ui.button.yellow {
  background-color: yellow !important;
  color: black !important;
}
.ui.button.yellow:active {
  background-color: rgb(248.625, 248.625, 0) !important;
}
.ui.button.olive {
  background-color: olive !important;
  color: black !important;
}
.ui.button.olive:active {
  background-color: rgb(121.625, 121.625, 0) !important;
}
.ui.button.green {
  background-color: green !important;
  color: black !important;
}
.ui.button.green:active {
  background-color: rgb(0, 121.625, 0) !important;
}
.ui.button.teal {
  background-color: teal !important;
  color: black !important;
}
.ui.button.teal:active {
  background-color: rgb(0, 121.625, 121.625) !important;
}
.ui.button.blue {
  background-color: blue !important;
  color: white !important;
}
.ui.button.blue:active {
  background-color: rgb(0, 0, 248.625) !important;
}
.ui.button.violet {
  background-color: violet !important;
  color: black !important;
}
.ui.button.violet:active {
  background-color: rgb(237.2367957746, 124.3882042254, 237.2367957746) !important;
}
.ui.button.purple {
  background-color: purple !important;
  color: white !important;
}
.ui.button.purple:active {
  background-color: rgb(121.625, 0, 121.625) !important;
}
.ui.button.pink {
  background-color: pink !important;
  color: black !important;
}
.ui.button.pink:active {
  background-color: rgb(255, 185.625, 197.7380952381) !important;
}
.ui.button.brown {
  background-color: brown !important;
  color: white !important;
}
.ui.button.brown:active {
  background-color: rgb(159.9184782609, 40.7065217391, 40.7065217391) !important;
}
.ui.button.grey {
  background-color: grey !important;
  color: black !important;
}
.ui.button.grey:active {
  background-color: rgb(124.8125, 124.8125, 124.8125) !important;
}
.ui.button.black {
  background-color: black !important;
  color: white !important;
}
.ui.button.black:active {
  background-color: black !important;
}
.ui.button > .icon {
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.025) !important;
}
.ui.action.input .ui.button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.ui.button.link {
  padding: 0;
  box-shadow: none !important;
  background-color: transparent !important;
  color: #ed6540 !important;
  text-decoration: underline;
}
.ui.button.link:hover {
  color: rgb(228.4688995215, 65.7894736842, 21.5311004785) !important;
}

.ui.input > input {
  border-color: #dfdfdf;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
  color: #343434 !important;
  font-family: "Open Sans";
  transition: none;
}
.ui.input > input:focus {
  border-color: rgb(191.125, 191.125, 191.125);
}

.ui[class*="left icon"].input > i.icon {
  z-index: 2;
}

.ui.right.labeled.input > input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-right: -1px;
}
.ui.right.labeled.input > input:focus {
  border-right-color: rgb(191.125, 191.125, 191.125) !important;
  z-index: 1;
}

.ui.action.input:not([class*="left action"]) > input:focus {
  border-right-color: rgb(191.125, 191.125, 191.125) !important;
  z-index: 1;
}

.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=time], .ui.form input[type=text], .ui.form input[type=file], .ui.form input[type=url] {
  border-color: #dfdfdf;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
  color: #343434 !important;
  font-family: "Open Sans";
  transition: none;
}
.ui.form input:not([type]):focus, .ui.form input[type=date]:focus, .ui.form input[type=datetime-local]:focus, .ui.form input[type=email]:focus, .ui.form input[type=number]:focus, .ui.form input[type=password]:focus, .ui.form input[type=search]:focus, .ui.form input[type=tel]:focus, .ui.form input[type=time]:focus, .ui.form input[type=text]:focus, .ui.form input[type=file]:focus, .ui.form input[type=url]:focus {
  border-color: rgb(191.125, 191.125, 191.125);
}

.ui.selection.dropdown {
  border-color: #dfdfdf;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
  color: #343434 !important;
  font-family: "Open Sans";
  transition: none;
}

.ui.basic.label {
  border-color: #dfdfdf;
  color: #343434;
}

.ui.segments {
  border: none !important;
}

.ui.segment {
  border: none !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1) !important;
}
.ui.segment.attached  {
  margin: 1rem 0em;
  width: auto;
  max-width: none;
}
.ui.segment .segments {
  box-shadow: none !important;
}
.ui.segment .segments .ui.segment {
  margin-bottom: 14px !important;
}
.ui.segment .segments .ui.segment:last-child {
  margin-bottom: 0 !important;
}
.ui.segment.emphasized {
  border-left: 10px solid #ed6540 !important;
  border-right: 10px solid #ed6540 !important;
}
.ui.segment.emphasized-left {
  border-left: 10px solid #286b74 !important;
}

.ui.header.unpublished {
  opacity: 0.5;
}

.ui.label.unpublished {
  opacity: 0.5;
}

.ui.header.unpublished .ui.label.unpublished {
  opacity: 1;
}

.inner-segment {
  padding: 1rem;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
}

.ui.menu.text.compact {
  min-height: 0;
  margin: -0.35714286em -0.5em;
}

.ui.table {
  color: #286b74;
}

.ui.sortable.table thead th {
  color: #343434;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 1;
}

.ui.table tfoot th {
  border: none;
  color: #286b74;
  font-weight: 500;
}

.custom-tab h2 {
  color: #286b74;
}
.custom-tab .active h2:after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #ed6540;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.page-header {
  padding: 2rem 0;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.01);
  background-color: #ffffff;
}

.page-header-title > a {
  text-decoration: none;
}

.page-main {
  padding: 1rem 0 4rem;
}
@media (min-height: 750px) {
  .page-main {
    padding: 1rem 0 8rem;
  }
}

.organization-statistics.ui.segment {
  background-color: rgba(247, 247, 247, 0.5);
  border: 2px solid #e8e8e8 !important;
  border-radius: 0.5rem !important;
  box-shadow: none !important;
}

.organization-statistic-item {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 2rem;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  background-color: #ffffff;
  text-align: center;
}
.organization-statistic-item > .ui.header {
  flex: 0 0 100%;
  color: #521b44;
  font-weight: 400;
}
.organization-statistic-item > .ui.header:after {
  content: "";
  display: block;
  height: 2px;
  width: 2rem;
  margin: 0.25rem auto 0;
  border-radius: 0.25rem;
  background-color: #521b44;
}
.organization-statistic-item > .ui.header > .sub.header {
  margin: 0.5rem 0 0;
  color: #286b74;
  font-size: 0.375em !important;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.organization-statistic-item-type-hr-users > .ui.header:after {
  background-color: #286b74;
}
.organization-statistic-item-type-users-reg > .ui.header:after {
  background-color: #286b74;
}
.organization-statistic-item-type-whitelisted-emails > .ui.header :after {
  background-color: #ed6540;
}

.organization-statistic-item-chart {
  padding: 0;
}
.organization-statistic-item-chart .rv-xy-plot__axis__title text {
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  fill: #286b74;
}
.organization-statistic-item-chart .rv-xy-plot__axis--horizontal .rv-xy-plot__axis__tick__text {
  font-weight: 700;
  fill: #343434;
}
.organization-statistic-item-chart .rv-xy-plot__axis--vertical .rv-xy-plot__axis__tick__text {
  font-weight: 400;
  fill: #521b44;
}

.language-selector-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.language-selector-container .language-selector-inner {
  position: relative;
  height: 100%;
}
.language-selector-container .language-selector-inner .language-selector {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  padding: 0.75em;
  border-radius: 0 0 1em 1em;
}

.subnav {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.subnav-item {
  margin: 5px;
  display: block;
  white-space: nowrap;
  padding: 5px;
  color: #ed6540;
  background-color: white;
  border-radius: 5px;
}
.subnav-item:hover {
  background-color: lightgrey;
}
.subnav-item.active {
  color: white;
  background-color: #ed6540;
}
.subnav-item.active:hover {
  background-color: rgb(237.5490430622, 105.6973684211, 69.8259569378);
}

.color-primary {
  color: #ed6540 !important;
}

.color-secondary {
  color: #286b74 !important;
}

.color-black {
  color: #000000 !important;
}

.color-white {
  color: #ffffff !important;
}

.line-height-1 {
  line-height: 1;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}

.no-transition {
  transition: none !important;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.position-relative {
  position: relative;
}

.position-aboslute {
  position: aboslute;
}

.position-fixed {
  position: fixed;
}

.position-static {
  position: static;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-justify {
  text-align: justify;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.min-width-0 {
  min-width: 0;
}

@media (min-width: 640px) {
  .color-primary-medium {
    color: #ed6540 !important;
  }
  .color-secondary-medium {
    color: #286b74 !important;
  }
  .color-black-medium {
    color: #000000 !important;
  }
  .color-white-medium {
    color: #ffffff !important;
  }
  .line-height-1-medium {
    line-height: 1;
  }
  .visually-hidden-medium {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
  }
  .no-transition-medium {
    transition: none !important;
  }
  .display-block-medium {
    display: block;
  }
  .display-flex-medium {
    display: flex;
  }
  .display-inline-medium {
    display: inline;
  }
  .display-inline-block-medium {
    display: inline-block;
  }
  .display-none-medium {
    display: none;
  }
  .position-relative-medium {
    position: relative;
  }
  .position-aboslute-medium {
    position: aboslute;
  }
  .position-fixed-medium {
    position: fixed;
  }
  .position-static-medium {
    position: static;
  }
  .text-align-center-medium {
    text-align: center;
  }
  .text-align-left-medium {
    text-align: left;
  }
  .text-align-right-medium {
    text-align: right;
  }
  .text-align-justify-medium {
    text-align: justify;
  }
  .ellipsis-medium {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .min-width-0-medium {
    min-width: 0;
  }
}
@media (min-width: 1024px) {
  .color-primary-large {
    color: #ed6540 !important;
  }
  .color-secondary-large {
    color: #286b74 !important;
  }
  .color-black-large {
    color: #000000 !important;
  }
  .color-white-large {
    color: #ffffff !important;
  }
  .line-height-1-large {
    line-height: 1;
  }
  .visually-hidden-large {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
  }
  .no-transition-large {
    transition: none !important;
  }
  .display-block-large {
    display: block;
  }
  .display-flex-large {
    display: flex;
  }
  .display-inline-large {
    display: inline;
  }
  .display-inline-block-large {
    display: inline-block;
  }
  .display-none-large {
    display: none;
  }
  .position-relative-large {
    position: relative;
  }
  .position-aboslute-large {
    position: aboslute;
  }
  .position-fixed-large {
    position: fixed;
  }
  .position-static-large {
    position: static;
  }
  .text-align-center-large {
    text-align: center;
  }
  .text-align-left-large {
    text-align: left;
  }
  .text-align-right-large {
    text-align: right;
  }
  .text-align-justify-large {
    text-align: justify;
  }
  .ellipsis-large {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .min-width-0-large {
    min-width: 0;
  }
}
@media (min-width: 1440px) {
  .color-primary-extra-large {
    color: #ed6540 !important;
  }
  .color-secondary-extra-large {
    color: #286b74 !important;
  }
  .color-black-extra-large {
    color: #000000 !important;
  }
  .color-white-extra-large {
    color: #ffffff !important;
  }
  .line-height-1-extra-large {
    line-height: 1;
  }
  .visually-hidden-extra-large {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
  }
  .no-transition-extra-large {
    transition: none !important;
  }
  .display-block-extra-large {
    display: block;
  }
  .display-flex-extra-large {
    display: flex;
  }
  .display-inline-extra-large {
    display: inline;
  }
  .display-inline-block-extra-large {
    display: inline-block;
  }
  .display-none-extra-large {
    display: none;
  }
  .position-relative-extra-large {
    position: relative;
  }
  .position-aboslute-extra-large {
    position: aboslute;
  }
  .position-fixed-extra-large {
    position: fixed;
  }
  .position-static-extra-large {
    position: static;
  }
  .text-align-center-extra-large {
    text-align: center;
  }
  .text-align-left-extra-large {
    text-align: left;
  }
  .text-align-right-extra-large {
    text-align: right;
  }
  .text-align-justify-extra-large {
    text-align: justify;
  }
  .ellipsis-extra-large {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .min-width-0-extra-large {
    min-width: 0;
  }
}
@media (max-width: 639px) {
  .color-primary-small-down {
    color: #ed6540 !important;
  }
  .color-secondary-small-down {
    color: #286b74 !important;
  }
  .color-black-small-down {
    color: #000000 !important;
  }
  .color-white-small-down {
    color: #ffffff !important;
  }
  .line-height-1-small-down {
    line-height: 1;
  }
  .visually-hidden-small-down {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
  }
  .no-transition-small-down {
    transition: none !important;
  }
  .display-block-small-down {
    display: block;
  }
  .display-flex-small-down {
    display: flex;
  }
  .display-inline-small-down {
    display: inline;
  }
  .display-inline-block-small-down {
    display: inline-block;
  }
  .display-none-small-down {
    display: none;
  }
  .position-relative-small-down {
    position: relative;
  }
  .position-aboslute-small-down {
    position: aboslute;
  }
  .position-fixed-small-down {
    position: fixed;
  }
  .position-static-small-down {
    position: static;
  }
  .text-align-center-small-down {
    text-align: center;
  }
  .text-align-left-small-down {
    text-align: left;
  }
  .text-align-right-small-down {
    text-align: right;
  }
  .text-align-justify-small-down {
    text-align: justify;
  }
  .ellipsis-small-down {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .min-width-0-small-down {
    min-width: 0;
  }
}
@media (max-width: 1023px) {
  .color-primary-medium-down {
    color: #ed6540 !important;
  }
  .color-secondary-medium-down {
    color: #286b74 !important;
  }
  .color-black-medium-down {
    color: #000000 !important;
  }
  .color-white-medium-down {
    color: #ffffff !important;
  }
  .line-height-1-medium-down {
    line-height: 1;
  }
  .visually-hidden-medium-down {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
  }
  .no-transition-medium-down {
    transition: none !important;
  }
  .display-block-medium-down {
    display: block;
  }
  .display-flex-medium-down {
    display: flex;
  }
  .display-inline-medium-down {
    display: inline;
  }
  .display-inline-block-medium-down {
    display: inline-block;
  }
  .display-none-medium-down {
    display: none;
  }
  .position-relative-medium-down {
    position: relative;
  }
  .position-aboslute-medium-down {
    position: aboslute;
  }
  .position-fixed-medium-down {
    position: fixed;
  }
  .position-static-medium-down {
    position: static;
  }
  .text-align-center-medium-down {
    text-align: center;
  }
  .text-align-left-medium-down {
    text-align: left;
  }
  .text-align-right-medium-down {
    text-align: right;
  }
  .text-align-justify-medium-down {
    text-align: justify;
  }
  .ellipsis-medium-down {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .min-width-0-medium-down {
    min-width: 0;
  }
}
@media (max-width: 1439px) {
  .color-primary-large-down {
    color: #ed6540 !important;
  }
  .color-secondary-large-down {
    color: #286b74 !important;
  }
  .color-black-large-down {
    color: #000000 !important;
  }
  .color-white-large-down {
    color: #ffffff !important;
  }
  .line-height-1-large-down {
    line-height: 1;
  }
  .visually-hidden-large-down {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
  }
  .no-transition-large-down {
    transition: none !important;
  }
  .display-block-large-down {
    display: block;
  }
  .display-flex-large-down {
    display: flex;
  }
  .display-inline-large-down {
    display: inline;
  }
  .display-inline-block-large-down {
    display: inline-block;
  }
  .display-none-large-down {
    display: none;
  }
  .position-relative-large-down {
    position: relative;
  }
  .position-aboslute-large-down {
    position: aboslute;
  }
  .position-fixed-large-down {
    position: fixed;
  }
  .position-static-large-down {
    position: static;
  }
  .text-align-center-large-down {
    text-align: center;
  }
  .text-align-left-large-down {
    text-align: left;
  }
  .text-align-right-large-down {
    text-align: right;
  }
  .text-align-justify-large-down {
    text-align: justify;
  }
  .ellipsis-large-down {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .min-width-0-large-down {
    min-width: 0;
  }
}
.flex-direction-column {
  flex-direction: column;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-flex-start {
  align-self: flex-start;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-self-stretch {
  align-self: stretch;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-flex-start {
  justify-self: flex-start;
}

.justify-self-flex-end {
  justify-self: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-basis-0 {
  flex-basis: 0;
}

.flex-basis-auto {
  flex-basis: auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.order--1 {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

@media (min-width: 640px) {
  .flex-direction-column-medium {
    flex-direction: column;
  }
  .flex-direction-column-reverse-medium {
    flex-direction: column-reverse;
  }
  .flex-direction-row-medium {
    flex-direction: row;
  }
  .flex-direction-row-reverse-medium {
    flex-direction: row-reverse;
  }
  .align-items-center-medium {
    align-items: center;
  }
  .align-items-flex-start-medium {
    align-items: flex-start;
  }
  .align-items-flex-end-medium {
    align-items: flex-end;
  }
  .align-self-center-medium {
    align-self: center;
  }
  .align-self-flex-start-medium {
    align-self: flex-start;
  }
  .align-self-flex-end-medium {
    align-self: flex-end;
  }
  .align-self-stretch-medium {
    align-self: stretch;
  }
  .justify-content-center-medium {
    justify-content: center;
  }
  .justify-content-flex-start-medium {
    justify-content: flex-start;
  }
  .justify-content-flex-end-medium {
    justify-content: flex-end;
  }
  .justify-self-center-medium {
    justify-self: center;
  }
  .justify-self-flex-start-medium {
    justify-self: flex-start;
  }
  .justify-self-flex-end-medium {
    justify-self: flex-end;
  }
  .flex-grow-1-medium {
    flex-grow: 1;
  }
  .flex-shrink-1-medium {
    flex-shrink: 1;
  }
  .flex-basis-0-medium {
    flex-basis: 0;
  }
  .flex-basis-auto-medium {
    flex-basis: auto;
  }
  .flex-wrap-medium {
    flex-wrap: wrap;
  }
  .order--1-medium {
    order: -1;
  }
  .order-0-medium {
    order: 0;
  }
  .order-1-medium {
    order: 1;
  }
}
@media (min-width: 1024px) {
  .flex-direction-column-large {
    flex-direction: column;
  }
  .flex-direction-column-reverse-large {
    flex-direction: column-reverse;
  }
  .flex-direction-row-large {
    flex-direction: row;
  }
  .flex-direction-row-reverse-large {
    flex-direction: row-reverse;
  }
  .align-items-center-large {
    align-items: center;
  }
  .align-items-flex-start-large {
    align-items: flex-start;
  }
  .align-items-flex-end-large {
    align-items: flex-end;
  }
  .align-self-center-large {
    align-self: center;
  }
  .align-self-flex-start-large {
    align-self: flex-start;
  }
  .align-self-flex-end-large {
    align-self: flex-end;
  }
  .align-self-stretch-large {
    align-self: stretch;
  }
  .justify-content-center-large {
    justify-content: center;
  }
  .justify-content-flex-start-large {
    justify-content: flex-start;
  }
  .justify-content-flex-end-large {
    justify-content: flex-end;
  }
  .justify-self-center-large {
    justify-self: center;
  }
  .justify-self-flex-start-large {
    justify-self: flex-start;
  }
  .justify-self-flex-end-large {
    justify-self: flex-end;
  }
  .flex-grow-1-large {
    flex-grow: 1;
  }
  .flex-shrink-1-large {
    flex-shrink: 1;
  }
  .flex-basis-0-large {
    flex-basis: 0;
  }
  .flex-basis-auto-large {
    flex-basis: auto;
  }
  .flex-wrap-large {
    flex-wrap: wrap;
  }
  .order--1-large {
    order: -1;
  }
  .order-0-large {
    order: 0;
  }
  .order-1-large {
    order: 1;
  }
}
@media (min-width: 1440px) {
  .flex-direction-column-extra-large {
    flex-direction: column;
  }
  .flex-direction-column-reverse-extra-large {
    flex-direction: column-reverse;
  }
  .flex-direction-row-extra-large {
    flex-direction: row;
  }
  .flex-direction-row-reverse-extra-large {
    flex-direction: row-reverse;
  }
  .align-items-center-extra-large {
    align-items: center;
  }
  .align-items-flex-start-extra-large {
    align-items: flex-start;
  }
  .align-items-flex-end-extra-large {
    align-items: flex-end;
  }
  .align-self-center-extra-large {
    align-self: center;
  }
  .align-self-flex-start-extra-large {
    align-self: flex-start;
  }
  .align-self-flex-end-extra-large {
    align-self: flex-end;
  }
  .align-self-stretch-extra-large {
    align-self: stretch;
  }
  .justify-content-center-extra-large {
    justify-content: center;
  }
  .justify-content-flex-start-extra-large {
    justify-content: flex-start;
  }
  .justify-content-flex-end-extra-large {
    justify-content: flex-end;
  }
  .justify-self-center-extra-large {
    justify-self: center;
  }
  .justify-self-flex-start-extra-large {
    justify-self: flex-start;
  }
  .justify-self-flex-end-extra-large {
    justify-self: flex-end;
  }
  .flex-grow-1-extra-large {
    flex-grow: 1;
  }
  .flex-shrink-1-extra-large {
    flex-shrink: 1;
  }
  .flex-basis-0-extra-large {
    flex-basis: 0;
  }
  .flex-basis-auto-extra-large {
    flex-basis: auto;
  }
  .flex-wrap-extra-large {
    flex-wrap: wrap;
  }
  .order--1-extra-large {
    order: -1;
  }
  .order-0-extra-large {
    order: 0;
  }
  .order-1-extra-large {
    order: 1;
  }
}
@media (max-width: 639px) {
  .flex-direction-column-small-down {
    flex-direction: column;
  }
  .flex-direction-column-reverse-small-down {
    flex-direction: column-reverse;
  }
  .flex-direction-row-small-down {
    flex-direction: row;
  }
  .flex-direction-row-reverse-small-down {
    flex-direction: row-reverse;
  }
  .align-items-center-small-down {
    align-items: center;
  }
  .align-items-flex-start-small-down {
    align-items: flex-start;
  }
  .align-items-flex-end-small-down {
    align-items: flex-end;
  }
  .align-self-center-small-down {
    align-self: center;
  }
  .align-self-flex-start-small-down {
    align-self: flex-start;
  }
  .align-self-flex-end-small-down {
    align-self: flex-end;
  }
  .align-self-stretch-small-down {
    align-self: stretch;
  }
  .justify-content-center-small-down {
    justify-content: center;
  }
  .justify-content-flex-start-small-down {
    justify-content: flex-start;
  }
  .justify-content-flex-end-small-down {
    justify-content: flex-end;
  }
  .justify-self-center-small-down {
    justify-self: center;
  }
  .justify-self-flex-start-small-down {
    justify-self: flex-start;
  }
  .justify-self-flex-end-small-down {
    justify-self: flex-end;
  }
  .flex-grow-1-small-down {
    flex-grow: 1;
  }
  .flex-shrink-1-small-down {
    flex-shrink: 1;
  }
  .flex-basis-0-small-down {
    flex-basis: 0;
  }
  .flex-basis-auto-small-down {
    flex-basis: auto;
  }
  .flex-wrap-small-down {
    flex-wrap: wrap;
  }
  .order--1-small-down {
    order: -1;
  }
  .order-0-small-down {
    order: 0;
  }
  .order-1-small-down {
    order: 1;
  }
}
@media (max-width: 1023px) {
  .flex-direction-column-medium-down {
    flex-direction: column;
  }
  .flex-direction-column-reverse-medium-down {
    flex-direction: column-reverse;
  }
  .flex-direction-row-medium-down {
    flex-direction: row;
  }
  .flex-direction-row-reverse-medium-down {
    flex-direction: row-reverse;
  }
  .align-items-center-medium-down {
    align-items: center;
  }
  .align-items-flex-start-medium-down {
    align-items: flex-start;
  }
  .align-items-flex-end-medium-down {
    align-items: flex-end;
  }
  .align-self-center-medium-down {
    align-self: center;
  }
  .align-self-flex-start-medium-down {
    align-self: flex-start;
  }
  .align-self-flex-end-medium-down {
    align-self: flex-end;
  }
  .align-self-stretch-medium-down {
    align-self: stretch;
  }
  .justify-content-center-medium-down {
    justify-content: center;
  }
  .justify-content-flex-start-medium-down {
    justify-content: flex-start;
  }
  .justify-content-flex-end-medium-down {
    justify-content: flex-end;
  }
  .justify-self-center-medium-down {
    justify-self: center;
  }
  .justify-self-flex-start-medium-down {
    justify-self: flex-start;
  }
  .justify-self-flex-end-medium-down {
    justify-self: flex-end;
  }
  .flex-grow-1-medium-down {
    flex-grow: 1;
  }
  .flex-shrink-1-medium-down {
    flex-shrink: 1;
  }
  .flex-basis-0-medium-down {
    flex-basis: 0;
  }
  .flex-basis-auto-medium-down {
    flex-basis: auto;
  }
  .flex-wrap-medium-down {
    flex-wrap: wrap;
  }
  .order--1-medium-down {
    order: -1;
  }
  .order-0-medium-down {
    order: 0;
  }
  .order-1-medium-down {
    order: 1;
  }
}
@media (max-width: 1439px) {
  .flex-direction-column-large-down {
    flex-direction: column;
  }
  .flex-direction-column-reverse-large-down {
    flex-direction: column-reverse;
  }
  .flex-direction-row-large-down {
    flex-direction: row;
  }
  .flex-direction-row-reverse-large-down {
    flex-direction: row-reverse;
  }
  .align-items-center-large-down {
    align-items: center;
  }
  .align-items-flex-start-large-down {
    align-items: flex-start;
  }
  .align-items-flex-end-large-down {
    align-items: flex-end;
  }
  .align-self-center-large-down {
    align-self: center;
  }
  .align-self-flex-start-large-down {
    align-self: flex-start;
  }
  .align-self-flex-end-large-down {
    align-self: flex-end;
  }
  .align-self-stretch-large-down {
    align-self: stretch;
  }
  .justify-content-center-large-down {
    justify-content: center;
  }
  .justify-content-flex-start-large-down {
    justify-content: flex-start;
  }
  .justify-content-flex-end-large-down {
    justify-content: flex-end;
  }
  .justify-self-center-large-down {
    justify-self: center;
  }
  .justify-self-flex-start-large-down {
    justify-self: flex-start;
  }
  .justify-self-flex-end-large-down {
    justify-self: flex-end;
  }
  .flex-grow-1-large-down {
    flex-grow: 1;
  }
  .flex-shrink-1-large-down {
    flex-shrink: 1;
  }
  .flex-basis-0-large-down {
    flex-basis: 0;
  }
  .flex-basis-auto-large-down {
    flex-basis: auto;
  }
  .flex-wrap-large-down {
    flex-wrap: wrap;
  }
  .order--1-large-down {
    order: -1;
  }
  .order-0-large-down {
    order: 0;
  }
  .order-1-large-down {
    order: 1;
  }
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("./assets/fonts/open-sans/OpenSans-VariableFont_wdth,wght.woff2") format("woff2");
  font-named-instance: "Regular";
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("./assets/fonts/open-sans/OpenSans-Italic-VariableFont_wdth,wght.woff2") format("woff2");
  font-named-instance: "Italic";
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/thin/thin.woff2") format("woff2"), url("./assets/fonts/barlow/thin/thin.woff") format("woff");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/thin-italic/thin-italic.woff2") format("woff2"), url("./assets/fonts/barlow/thin-italic/thin-italic.woff") format("woff");
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/extra-light/extra-light.woff2") format("woff2"), url("./assets/fonts/barlow/extra-light/extra-light.woff") format("woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/extra-light-italic/extra-light-italic.woff2") format("woff2"), url("./assets/fonts/barlow/extra-light-italic/extra-light-italic.woff") format("woff");
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/light/light.woff2") format("woff2"), url("./assets/fonts/barlow/light/light.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/light-italic/light-italic.woff2") format("woff2"), url("./assets/fonts/barlow/light-italic/light-italic.woff") format("woff");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/regular/regular.woff2") format("woff2"), url("./assets/fonts/barlow/regular/regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/regular-italic/regular-italic.woff2") format("woff2"), url("./assets/fonts/barlow/regular-italic/regular-italic.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/medium/medium.woff2") format("woff2"), url("./assets/fonts/barlow/medium/medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/medium-italic/medium-italic.woff2") format("woff2"), url("./assets/fonts/barlow/medium-italic/medium-italic.woff") format("woff");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/semi-bold/semi-bold.woff2") format("woff2"), url("./assets/fonts/barlow/semi-bold/semi-bold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/semi-bold-italic/semi-bold-italic.woff2") format("woff2"), url("./assets/fonts/barlow/semi-bold-italic/semi-bold-italic.woff") format("woff");
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/bold/bold.woff2") format("woff2"), url("./assets/fonts/barlow/bold/bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/bold-italic/bold-italic.woff2") format("woff2"), url("./assets/fonts/barlow/bold-italic/bold-italic.woff") format("woff");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/extra-bold/extra-bold.woff2") format("woff2"), url("./assets/fonts/barlow/extra-bold/extra-bold.woff") format("woff");
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/extra-bold-italic/extra-bold-italic.woff2") format("woff2"), url("./assets/fonts/barlow/extra-bold-italic/extra-bold-italic.woff") format("woff");
  font-style: italic;
  font-weight: 800;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/black/black.woff2") format("woff2"), url("./assets/fonts/barlow/black/black.woff") format("woff");
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "Barlow";
  src: url("./assets/fonts/barlow/black-italic/black-italic.woff2") format("woff2"), url("./assets/fonts/barlow/black-italic/black-italic.woff") format("woff");
  font-style: italic;
  font-weight: 900;
}

