@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables';

@import 'base/mixins';
@import 'base/grid';
@import 'base/container';
@import 'base/common';

@import 'components/page';
@import 'components/statistics';
@import 'components/languageSelector';
@import 'components/subnav';

@import 'classes/common';
@import 'classes/flex';

@import 'fonts/open-sans';
@import 'fonts/barlow';
