.subnav {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  &-item {
    margin: 5px;
    display: block;
    white-space: nowrap;
    padding: 5px;
    color: $primary-color;
    background-color: white;
    border-radius: 5px;
    &:hover {
      background-color: lightgrey;
    }
    &.active {
      color: white;
      background-color: $primary-color;
      &:hover {
        background-color: lighten($primary-color, 1.25);
      }
    }
  }
}