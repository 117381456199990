* {
  @apply antialiased;
}

html {
  // margin-left: calc(100vw - 100%);
  overflow: visible !important;
}

body {
  background-color: $background-color;
  color: $secondary-color;
  font-weight: 500;
  line-height: 1.375;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.375;
  letter-spacing: -0.00625em;
  a {
    font-family: $primary-font-family;
  }
}

a {
  color: $primary-color;
  text-decoration: underline;
  font-family: $secondary-font-family;
  &:hover {
    color: darken($primary-color, 10%);
    text-decoration: underline;
  }
}

label,
.label {
  color: $primary-alt-color;
  font-weight: 700;
}
.form-label {
  display: inline-block;
  margin: 0 0 7px;
}

.ui.header {
  color: $secondary-color;
  font-weight: 800;
  line-height: 1.125;
  text-rendering: optimizeLegibility;
  .sub.header {
    color: inherit;
    font-size: 0.5em !important;
    line-height: inherit;
  }

  &.page-title {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.secondary {
    color: $primary-alt-color;
    font-weight: 700;
  }
}

p {
  .ui.button {
    &.link {
      font-family: $primary-font-family;
      font-weight: 500;
      letter-spacing: -0.00625em;
    }
  }
}

.ui.button {
  margin: 0;
  border-radius: $border-radius !important;
  box-shadow: 0 0 0 1px $border-color inset !important;
  background-color: $background-color !important;
  color: $primary-alt-color !important;
  font-family: $secondary-font-family;
  font-weight: 400;
  transition: none;
  &:hover,
  &:focus {
    box-shadow: 0 0 0 1px darken($border-color, 12.5%) inset !important;
  }
  &:active {
    background-color: darken($background-color, 1.25%) !important;
  }
  // I'm sorry... Importants everywhere and I had to continue that trend or redo
  // everything CSS. Whoever it may concern. I know this is not proper, but I had legacy
  // and no time.
  @each $colName, $bgCol, $txtCol in $colors {
    &.#{$colName} {
      background-color: $bgCol !important;
      color: $txtCol !important;
      &:active {
        background-color: darken($bgCol, 1.25%) !important;
      }
    }
  }
  > .icon {
    box-shadow: none !important;
    background-color: rgba($black, 0.025) !important;
  }
  .ui.action.input & {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &.link {
    padding: 0;
    box-shadow: none !important;
    background-color: transparent !important;
    color: $primary-color !important;
    text-decoration: underline;
    &:hover {
      color: darken($primary-color, 10%) !important;
    }
  }
}

.ui.input {
  > input {
    border-color: $border-color;
    border-radius: $border-radius !important;
    box-shadow: none !important;
    color: $primary-alt-color !important;
    font-family: $secondary-font-family;
    transition: none;
    &:focus {
      border-color: darken($border-color, 12.5%);
    }
  }
}
.ui[class*='left icon'].input > i.icon {
  z-index: 2;
}
.ui.right.labeled.input > input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-right: -1px;
  &:focus {
    border-right-color: darken($border-color, 12.5%) !important;
    z-index: 1;
  }
}
.ui.action.input:not([class*='left action']) > input {
  &:focus {
    border-right-color: darken($border-color, 12.5%) !important;
    z-index: 1;
  }
}
.ui.form {
  input {
    &:not([type]),
    &[type='date'],
    &[type='datetime-local'],
    &[type='email'],
    &[type='number'],
    &[type='password'],
    &[type='search'],
    &[type='tel'],
    &[type='time'],
    &[type='text'],
    &[type='file'],
    &[type='url'] {
      border-color: $border-color;
      border-radius: $border-radius !important;
      box-shadow: none !important;
      color: $primary-alt-color !important;
      font-family: $secondary-font-family;
      transition: none;
      &:focus {
        border-color: darken($border-color, 12.5%);
      }
    }
  }
}
.ui.selection.dropdown {
  border-color: $border-color;
  border-radius: $border-radius !important;
  box-shadow: none !important;
  color: $primary-alt-color !important;
  font-family: $secondary-font-family;
  transition: none;
}

.ui.basic.label {
  border-color: $border-color;
  color: $primary-alt-color;
}

.ui.segments {
  border: none !important;
}

.ui.segment {
  border: none !important;
  border-radius: $border-radius !important;
  box-shadow: 0 6px 14px 0 rgba($black, 0.1) !important;
  &.attached  {
    margin: 1rem 0em;
    width: auto;
    max-width: none;
  }
  .segments {
    box-shadow: none !important;
    .ui.segment {
      margin-bottom: 14px !important;
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
  &.emphasized {
    border-left: 10px solid $primary-color !important;
    border-right: 10px solid $primary-color !important;
  }

  &.emphasized-left {
    border-left: 10px solid $secondary-color !important;
  }
}

.ui.header.unpublished {
  opacity: 0.5;
}

.ui.label.unpublished {
  opacity: 0.5;
}

.ui.header.unpublished .ui.label.unpublished {
  opacity: 1;
}

.inner-segment {
  padding: 1rem;
  border: 1px solid $border-color;
  border-radius: $border-radius;
}

.ui.menu.text {
  &.compact {
    min-height: 0;
    margin: -0.35714286em -0.5em;
  }
}

.ui.table {
  color: $secondary-color;
}
.ui.sortable.table thead th {
  color: $primary-alt-color;
  font-family: $secondary-font-family;
  font-weight: 400;
  line-height: 1;
}
.ui.table tfoot th {
  border: none;
  color: $secondary-color;
  font-weight: 500;
}

// .custom-tab {
//   > .ui.text.menu {
//     min-height: 0;
//     margin: 0 -0.5em 1em;
//     > .item {
//       margin: 0 0.5em;
//       padding: 0;
//       &:not(.active) {
//         > .ui.header {
//           font-weight: 400;
//         }
//       }
//       &.active {
//         > .ui.header {
//           position: relative;
//           &:after {
//             content: '';
//             height: 2px;
//             width: 100%;
//             background-color: $primary-color;
//             position: absolute;
//             bottom: -2px;
//             left: 0;
//           }
//         }
//       }
//     }
//   }
// }
.custom-tab {
  h2 {
    color: $secondary-color;
  }
  .active {
    h2 {
      &:after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: $primary-color;
        position: absolute;
        bottom: -2px;
        left: 0;
      }
    }
  }
}
