$open-sans: 'Open Sans';
$open-sans-path: './assets/fonts/open-sans';


@font-face {
  font-family: $open-sans;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("./assets/fonts/open-sans/OpenSans-VariableFont_wdth\,wght.woff2") format("woff2");
  font-named-instance: "Regular";
}

@font-face {
  font-family: $open-sans;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("./assets/fonts/open-sans/OpenSans-Italic-VariableFont_wdth\,wght.woff2") format("woff2");
  font-named-instance: "Italic";
}
