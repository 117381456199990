.page-header {
  padding: 2rem 0;
  box-shadow: 0 6px 14px 0 rgba($black, 0.01);
  background-color: $white;
}
.page-header-title {
  > a {
    text-decoration: none;
  }
}
.page-main {
  padding: 1rem 0 4rem;
  @media (min-height: 750px) {
    padding: 1rem 0 8rem;
  }
}
