.organization-statistics.ui.segment {
  background-color: rgba(#f7f7f7, 0.5);
  border: 2px solid #e8e8e8 !important;
  border-radius: $border-radius * 2 !important;
  box-shadow: none !important;
}
.organization-statistic-item {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 2rem;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  background-color: $white;
  text-align: center;
  >.ui.header {
    flex: 0 0 100%;
    color: $secondary-alt-color;
    font-weight: 400;
    &:after {
      content: '';
      display: block;
      height: 2px;
      width: 2rem;
      margin: 0.25rem auto 0;
      border-radius: $border-radius;
      background-color: $secondary-alt-color;
    }
    >.sub.header {
      margin: 0.5rem 0 0;
      color: $secondary-color;
      font-size: 0.375em !important;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
  }
}
.organization-statistic-item-type {
  &-hr-users {
    >.ui.header {
      &:after{
        background-color: $secondary-color;
      }
    }
  }
  &-users-reg {
    >.ui.header {
      &:after{
        background-color: $secondary-color;
      }
    }
  }
  &-whitelisted-emails {
    >.ui.header {
      &:after{
        background-color: $primary-color;
      }
    }
  }
}
.organization-statistic-item-chart {
  padding: 0;
  .rv-xy-plot__axis__title {
    text {
      font-size: 9px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      fill: $secondary-color,
    }
  }
  .rv-xy-plot__axis--horizontal {
    .rv-xy-plot__axis__tick__text {
      font-weight: 700;
      fill: $primary-alt-color;
    }
  }
  .rv-xy-plot__axis--vertical {
    .rv-xy-plot__axis__tick__text {
      font-weight: 400;
      fill: $secondary-alt-color;
    }
  }
}
