@each $breakpoint in map-keys($breakpoints) {
  $breakpoint-class: if($breakpoint == small, '', '-#{$breakpoint}');
  $breakpoint-param: if($breakpoint == small, false, $breakpoint);

  @include breakpoint($breakpoint-param) {

    .flex-direction-column#{$breakpoint-class} {
      flex-direction: column;
    }
    .flex-direction-column-reverse#{$breakpoint-class} {
      flex-direction: column-reverse;
    }
    .flex-direction-row#{$breakpoint-class} {
      flex-direction: row;
    }
    .flex-direction-row-reverse#{$breakpoint-class} {
      flex-direction: row-reverse;
    }

    .align-items-center#{$breakpoint-class} {
      align-items: center;
    }
    .align-items-flex-start#{$breakpoint-class} {
      align-items: flex-start;
    }
    .align-items-flex-end#{$breakpoint-class} {
      align-items: flex-end;
    }

    .align-self-center#{$breakpoint-class} {
      align-self: center;
    }
    .align-self-flex-start#{$breakpoint-class} {
      align-self: flex-start;
    }
    .align-self-flex-end#{$breakpoint-class} {
      align-self: flex-end;
    }
    .align-self-stretch#{$breakpoint-class} {
      align-self: stretch;
    }

    .justify-content-center#{$breakpoint-class} {
      justify-content: center;
    }
    .justify-content-flex-start#{$breakpoint-class} {
      justify-content: flex-start;
    }
    .justify-content-flex-end#{$breakpoint-class} {
      justify-content: flex-end;
    }

    .justify-self-center#{$breakpoint-class} {
      justify-self: center;
    }
    .justify-self-flex-start#{$breakpoint-class} {
      justify-self: flex-start;
    }
    .justify-self-flex-end#{$breakpoint-class} {
      justify-self: flex-end;
    }

    .flex-grow-1#{$breakpoint-class} {
      flex-grow: 1;
    }
    .flex-shrink-1#{$breakpoint-class} {
      flex-shrink: 1;
    }

    .flex-basis-0#{$breakpoint-class} {
      flex-basis: 0;
    }
    .flex-basis-auto#{$breakpoint-class} {
      flex-basis: auto;
    }
    .flex-wrap#{$breakpoint-class} {
      flex-wrap: wrap;
    }

    .order--1#{$breakpoint-class} {
      order: -1;
    }
    .order-0#{$breakpoint-class} {
      order: 0;
    }
    .order-1#{$breakpoint-class} {
      order: 1;
    }

  }
}
