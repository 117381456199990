.container {
  max-width: $page-width + (14px * 2);
  margin: 0 auto;
  padding: 0 14px;
}
.container-narrow {
  max-width: $page-width-narrow + (14px * 2);
}
.container-extra-narrow {
  max-width: $page-width-extra-narrow + (14px * 2);
}

.max-width {
  max-width: $page-width;
  margin: 0 auto;
}
.max-width-narrow {
  max-width: $page-width-narrow;
}
.max-width-extra-narrow {
  max-width: $page-width-extra-narrow;
}
