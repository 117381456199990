.language-selector-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    .language-selector-inner {
      position: relative;
      height: 100%;
      .language-selector {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: $white;
        padding: .75em;
        border-radius: 0 0 1em 1em;
      }
    }
  }