@each $breakpoint in map-keys($breakpoints) {
  $breakpoint-class: if($breakpoint == small, '', '-#{$breakpoint}');
  $breakpoint-param: if($breakpoint == small, false, $breakpoint);

  @include breakpoint($breakpoint-param) {

    .color-primary#{$breakpoint-class} {
      color: $primary-color !important;
    }
    .color-secondary#{$breakpoint-class} {
      color: $secondary-color !important;
    }
    .color-black#{$breakpoint-class} {
      color: $black !important;
    }
    .color-white#{$breakpoint-class} {
      color: $white !important;
    }

    .line-height-1#{$breakpoint-class} {
      line-height: 1;
    }

    .visually-hidden#{$breakpoint-class} {
      @include visually-hidden();
    }

    .no-transition#{$breakpoint-class} {
      transition: none !important;
    }

    .display-block#{$breakpoint-class} {
      display: block;
    }
    .display-flex#{$breakpoint-class} {
      display: flex;
    }
    .display-inline#{$breakpoint-class} {
      display: inline;
    }
    .display-inline-block#{$breakpoint-class} {
      display: inline-block;
    }
    .display-none#{$breakpoint-class} {
      display: none;
    }

    .position-relative#{$breakpoint-class} {
      position: relative;
    }
    .position-aboslute#{$breakpoint-class} {
      position: aboslute;
    }
    .position-fixed#{$breakpoint-class} {
      position: fixed;
    }
    .position-static#{$breakpoint-class} {
      position: static;
    }

    .text-align-center#{$breakpoint-class} {
      text-align: center;
    }
    .text-align-left#{$breakpoint-class} {
      text-align: left;
    }
    .text-align-right#{$breakpoint-class} {
      text-align: right;
    }
    .text-align-justify#{$breakpoint-class} {
      text-align: justify;
    }

    .ellipsis#{$breakpoint-class} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .min-width-0#{$breakpoint-class} {
      min-width: 0;
    }
  }
}
