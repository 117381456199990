$barlow: 'Barlow';
$barlow-path: './assets/fonts/barlow';

@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'thin');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'thin-italic');
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'extra-light');
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'extra-light-italic');
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'light');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'light-italic');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'regular');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'regular-italic');
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'medium');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'medium-italic');
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'semi-bold');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'semi-bold-italic');
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'bold');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'bold-italic');
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'extra-bold');
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'extra-bold-italic');
  font-style: italic;
  font-weight: 800;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'black');
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: $barlow;
  @include font-face-src($barlow-path, 'black-italic');
  font-style: italic;
  font-weight: 900;
}
