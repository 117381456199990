@mixin font-face-src($path, $type) {
  src: url('#{$path}/#{$type}/#{$type}.woff2') format('woff2'),
       url('#{$path}/#{$type}/#{$type}.woff') format('woff');
}

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else if $breakpoint {
    @media #{$breakpoint} {
      @content;
    }
  } @else if $breakpoint == false {
    @content;
  } @else {
    @warn "Error";
  }
}

@mixin visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}
